import {Action} from '@ngrx/store';
import {User} from '../../model/user';
import {HttpErrorResponse} from '@angular/common/http';

export enum ActiveUserActionType {
  GET = '[ActiveUser] Get',
  GET_SUCCESS = '[ActiveUser] Get :: Success',

  SET_ACTIVE_USER = '[ActiveUser] Set Active User',
  SET_ACTIVE_USER_PRIVILEGES = '[ActiveUser] Set Active User Privileges',

  UPLOAD_IMG = '[ActiveUser] Upload Image',
  UPLOAD_IMG_SUCCESS = '[ActiveUser] Upload Image Success',

  DELETE_IMG = '[ActiveUser] Delete Image',
  DELETE_IMG_SUCCESS = '[ActiveUser] Delete Image Success',

  CLEAR = '[ActiveUser] Clear',
}

export class GetActiveUserAction implements Action {
  public readonly type = ActiveUserActionType.GET;

  public constructor(
    public payload: {
      email: string;
      onSuccess?: (user: User) => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class GetActiveUserSuccessAction implements Action {
  public readonly type = ActiveUserActionType.GET_SUCCESS;

  public constructor(public payload: {user: User}) {}
}

export class SetActiveUser implements Action {
  public readonly type = ActiveUserActionType.SET_ACTIVE_USER;

  public constructor(
    public payload: {
      user: User;
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class SetActiveUserPrivileges implements Action {
  public readonly type = ActiveUserActionType.SET_ACTIVE_USER_PRIVILEGES;

  public constructor(
    public payload: {
      privileges: string[];
      onSuccess?: () => void;
      onFailure?: (error: Error) => void;
    }
  ) {}
}

export class UploadImageAction implements Action {
  public readonly type = ActiveUserActionType.UPLOAD_IMG;

  public constructor(
    public payload: {
      image: Object;
      onSuccess?: (url: string) => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class UploadImageSuccessAction implements Action {
  public readonly type = ActiveUserActionType.UPLOAD_IMG_SUCCESS;
}

export class DeleteImageAction implements Action {
  public readonly type = ActiveUserActionType.DELETE_IMG;

  public constructor(
    public payload: {
      ids: string;
      onSuccess?: () => void;
      onFailure?: (error: HttpErrorResponse) => void;
    }
  ) {}
}

export class DeleteImageSuccessAction implements Action {
  public readonly type = ActiveUserActionType.DELETE_IMG_SUCCESS;
}

export type ActiveUserAction =
  | SetActiveUser
  | SetActiveUserPrivileges
  | GetActiveUserAction
  | GetActiveUserSuccessAction
  | UploadImageAction
  | UploadImageSuccessAction
  | DeleteImageAction
  | DeleteImageSuccessAction;
