@if (
  {
    projects: projects$ | async,
    projectsMap: projectsMap$ | async,
    selectedProject: selectedProject$ | async,
    view: view$ | async,
    loading: loading$ | async,
    projectView: projectView$ | async,
    usersMap: usersMap$ | async,
    taskTemplates: taskTemplates$ | async
  };
  as state
) {
  <!-- --------------INITIAL PROJECTS LOADING---------------- -->
  @if (!state.projects) {
    <div class="loading-container">
      <oph-loading></oph-loading>
    </div>
  } @else {
    <!-- --------------NO PROJECTS---------------- -->
    @if (!state.projects.length) {
      <create-first type="project" (buttonClick)="onOpenProjectDialog()"></create-first>
    } @else {
      <div class="main-container" #scrollContainer (scroll)="recordScrollPosition()">
        <!-- --------------PROJECTS---------------- -->
        <!-- --------------HEADER---------------- -->
        <header>
          <div class="header-top-container">
            <project-selector [selectedProject]="state.selectedProject" [loading]="state.loading?.projectAction">
              <!-- PROJECT MENU ITEMS -->
              <ng-container class="project-selector">
                <div class="create-project-button-container">
                  <oph-button
                    color="orange"
                    [style]="{width: '100%', minWidth: '300px'}"
                    (buttonClick)="onOpenProjectDialog()"
                  >
                    <mat-icon svgIcon="plus"></mat-icon>
                    New Project</oph-button
                  >
                </div>
                @for (project of state.projects; track project) {
                  <project-selector-menu-project-button
                    [project]="project"
                    [isSelected]="project?.label === state.selectedProject?.label"
                    (buttonClick)="selectProject(project)"
                  ></project-selector-menu-project-button>
                }
              </ng-container>
              <!-- PROJECT ACTION MENU ITEMS -->
              @for (option of projectActionOptions; track option) {
                <project-selector-menu-action-button
                  class="project-menu"
                  [option]="option"
                  (buttonClick)="onProjectAction(option.action)"
                ></project-selector-menu-action-button>
              }
            </project-selector>
            <oph-button-group
              [options]="viewOptions"
              [selectedOptionName]="state.view"
              (optionClick)="onViewChange($event)"
            ></oph-button-group>
          </div>
          <projects-sked-selector [skeds]="projectSkeds$ | async" (addSked)="onAddSked()"></projects-sked-selector>
        </header>

        <div class="content-container">
          <!-- --------------VIEW---------------- -->
          @if (state.view === 'view') {
            @if (state.projectView && state.usersMap) {
              <project-view
                [project]="projectView$ | async"
                [usersMap]="state.usersMap"
                [selectedRoutine]="selectedRoutine$ | async"
                (selectedRoutineChange)="onSelectedRoutineChange($event)"
              ></project-view>
            } @else {
              <div class="loading-container lc-sm">
                <oph-loading></oph-loading>
              </div>
            }
          }
          @if (state.view === 'build') {
            <!-- --------------EDIT---------------- -->
            @if (state.taskTemplates) {
              <project-edit [project]="state.selectedProject" [taskTemplates]="taskTemplates$ | async"></project-edit>
            } @else {
              <div class="loading-container lc-sm">
                <oph-loading></oph-loading>
              </div>
            }
          }
        </div>
      </div>
    }
  }
}
