<div class="input-container">
  <input
    [class.orange-border]="orangeBorder && !control?.value"
    [formControl]="control"
    [ngStyle]="inputStyle"
    [type]="type"
    [class.button-padding]="showUpdateButton"
    (focus)="onFocus()"
    (blur)="onBlur()"
    #input
  />

  @if (showUpdateButton) {
    <button
      [class.focused-button]="(inputFocused$ | async) || !!updateButtonStatus"
      [ngStyle]="buttonStyle"
      [disabled]="!!updateButtonStatus"
      (click)="onUpdate($event)"
    >
      @if (updateButtonStatus === 'loading') {
        <mat-spinner class="custom-color" diameter="20"></mat-spinner>
      } @else if (updateButtonStatus === 'success') {
        <mat-icon svgIcon="check"></mat-icon>
      } @else {
        Update
      }
    </button>
  }
</div>
