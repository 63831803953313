@if ({showIconPicker: showIconPicker$ | async}; as state) {
  <div [style.max-width]="state.showIconPicker ? '650px' : '518px'" class="basic-info-container">
    @if (!state.showIconPicker) {
      <div class="relative-container">
        <input class="input" placeholder="Task name" #taskNameInput [formControl]="labelControl" maxlength="100" />
        <span class="character-count">{{ labelControl.value.length || 0 }}/100</span>
      </div>
      <div class="relative-container textarea-container">
        <textarea
          class="input"
          placeholder="Task description"
          [formControl]="descriptionControl"
          maxlength="220"
        ></textarea>
        <span class="character-count">{{ descriptionControl.value.length || 0 }}/220</span>
      </div>
      <div class="icon-duration-container">
        <task-icon-picker-button
          [iconName]="iconControl.value"
          lightBackground="true"
          (buttonClick)="onShowIconPicker()"
        ></task-icon-picker-button>
        @if (!eventControl.value) {
          <div class="bottom-container-right">
            <span class="how-long-text">How long should it take?</span>
            <task-duration-slider [control]="durationControl"></task-duration-slider>
          </div>
        }
      </div>
      <div class="priority-container">
        <span>Set priority</span>
        <task-priority-selector [control]="priorityControl" lightBackground="true"></task-priority-selector>
      </div>
      <task-location-selector [control]="locationControl" lightBackground="true"></task-location-selector>
    }
    @if (state.showIconPicker) {
      <div class="task-icon-picker-container">
        <task-icon-picker (iconChange)="onIconChange($event)"></task-icon-picker>
      </div>
    }
  </div>
}
