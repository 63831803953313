import {environment} from '../environments/environment';

export const WEBROOT = environment.apiUrl;
export interface PrivilegeDto {
  __v: number;
  _id: string;
  name: string;
}

export interface Privilege {
  id: string;
  name: string;
  v: number;
}

export interface MenuItem {
  display: string;
  url?: string;
  icon?: string;
}

export const MENU_ITEMS: MenuItem[] = [
  {
    display: 'Current Sked',
    url: '/current-sked',
    icon: 'sked-list',
  },
  {
    display: 'Planning',
  },
  {
    display: 'Goals',
    url: '/goals',
    icon: 'target',
  },
  {
    display: 'Schedule',
    url: '/schedule',
    icon: 'calendar',
  },
  // {
  //   display: 'Team Schedule',
  //   url: '/team-schedule',
  //   icon: 'calendar',
  // },
  {
    display: 'Components',
  },
  {
    display: 'Projects',
    url: '/projects',
    icon: 'project',
  },
  // {
  //   display: 'Actions',
  //   url: '/actions',
  //   icon: 'bolt',
  // },
  {
    display: 'Locations',
    url: '/locations',
    icon: 'map-pin',
  },
  {
    display: 'Trackers',
    url: '/trackers',
    icon: 'tracker',
  },
  {
    display: 'Skills',
    url: '/skills',
    icon: 'star',
  },
  {
    display: 'Programs',
    url: '/programs',
    icon: 'book',
  },
  // {
  //   display: 'Reporting old',
  //   url: '/reporting-old',
  //   icon: 'reporting',
  // },
  // {
  //   display: 'Reporting',
  //   url: '/reporting',
  //   icon: 'reporting',
  // },
  {
    display: 'Settings',
  },
  {
    display: 'Teams',
    url: '/teams',
    icon: 'users',
  },
  {
    display: 'Users',
    url: '/users',
    icon: 'users',
  },
  {
    display: 'Roles',
    url: '/roles',
    icon: 'users',
  },
  {
    display: 'Lenses',
    url: '/lenses',
    icon: 'users',
  },
];

export const colors: string[] = [
  '#201F20',
  '#7B61FF',
  '#5E4EAD',
  '#372499',
  '#FC7F7F',
  '#EF5656',
  '#CA3131',
  '#61C6FF',
  '#43A6DE',
  '#197FB8',
  '#B8B8B8',
  '#FFE168',
  '#EAC83D',
  '#D1AC1A',
  '#62F1AD',
  '#34D187',
  '#0FAB61',
  '#FFB661',
  '#E59639',
  '#BD7014',
  '#D7D7D7',
  '#FDA0FF',
  '#EF74F1',
  '#CF31D2',
  '#86A8FF',
  '#4372EF',
  '#214EC6',
  '#D783FF',
  '#B546EA',
  '#9D1EDA',
  '#EDEDED',
  '#ACEE78',
  '#80D240',
  '#5EAD20',
  '#FF965B',
  '#E97533',
  '#D94F00',
  '#FF9CD2',
  '#E161A6',
  '#D42783',
];

export const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];

export interface DayOfWeek {
  display: string;
  dayNumber: number;
}

export const DAYS_OF_WEEK: DayOfWeek[] = [
  {display: 'Monday', dayNumber: 0},
  {display: 'Tuesday', dayNumber: 1},
  {display: 'Wednesday', dayNumber: 2},
  {display: 'Thursday', dayNumber: 3},
  {display: 'Friday', dayNumber: 4},
  {display: 'Saturday', dayNumber: 5},
  {display: 'Sunday', dayNumber: 6},
];

export const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
export const ALLOWED_IMAGE_TYPES_WARNING = 'Unsupported image type. Allowed image types: JPEG, JPG, PNG, GIF';
export const IMAGE_UPLOAD_MAX_SIZE = 10; //mb
