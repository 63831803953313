import {ChangeDetectionStrategy, Component, Inject, OnDestroy} from '@angular/core';
import {AbstractControl, FormArray, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Subscription, startWith} from 'rxjs';
import {ProjectTask} from 'src/app/core/model/task';
import {DeleteImageAction} from 'src/app/core/store/active-user/active-user.action';
import {
  CreateTaskTemplateAction,
  DeleteTaskTemplateAction,
  GetTaskTemplatesAction,
  UpdateProjectTasksAction,
  UpdateTaskTemplateAction,
} from 'src/app/core/store/projects/projects.action';
import {GetAllTrackersAction} from 'src/app/core/store/trackers/trackers.action';
import {ProjectsService} from '../../services/projects.service';
import {TasksService} from '../../services/tasks.service';
import {MessageService} from './../../../services/message.service';
import {TaskDialogPanels, TaskDialogTabs} from './shared/task-dialog-constants';
import {Project} from 'src/app/core/model/project';

@Component({
  selector: 'task-dialog',
  templateUrl: './task-dialog.component.html',
  styleUrls: ['./task-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogComponent implements OnDestroy {
  subscriptions = new Subscription();

  selectedPanel$ = new BehaviorSubject<TaskDialogPanels>(TaskDialogPanels.Default);
  selectedTab$ = new BehaviorSubject<TaskDialogTabs>(TaskDialogTabs.Details);
  validationErrors$ = new BehaviorSubject<Record<string, boolean>>({});
  loadingSave$ = new BehaviorSubject<boolean>(false);
  triggerCount$ = new BehaviorSubject<number>(0);

  form: FormGroup;
  project: Project;
  task: ProjectTask;
  template: boolean;
  TaskDialogPanels = TaskDialogPanels;
  TaskDialogTabs = TaskDialogTabs;
  areTriggersComplete: boolean;
  imagesToDelete: string[] = [];
  templateSearchValue: string;

  constructor(
    private tasksService: TasksService,
    private projectsService: ProjectsService,
    @Inject(MAT_DIALOG_DATA)
    public data: {task: ProjectTask; project: Project; template?: boolean; templateSearchValue?: string},
    private dialog: MatDialogRef<TaskDialogComponent>,
    private store$: Store,
    private messageService: MessageService
  ) {
    this.project = data.project;
    this.task = data.task;
    this.template = data.template;
    this.templateSearchValue = data.templateSearchValue;
    this.form = this.tasksService.getTaskForm(data?.task || null, data.template);

    this.store$.dispatch(new GetAllTrackersAction({params: {projectId: this.project._id, pageSize: 100}}));

    if (!data.template) {
      this.subscriptions.add(this.setTriggerCount());
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setTriggerCount(): Subscription {
    return this.triggerFormArray?.valueChanges.pipe(startWith(this.triggerFormArray.value)).subscribe(triggers => {
      if (!triggers) {
        return;
      }
      let triggerCount = 0;
      const triggerCompleteArray = [];
      triggers.forEach(trigger => {
        if (this.tasksService.checkIfTriggerIsComplete(trigger)) {
          triggerCount++;
          triggerCompleteArray.push(true);
        } else {
          triggerCompleteArray.push(false);
        }
      });
      this.areTriggersComplete = triggerCompleteArray.every(complete => complete);
      this.triggerCount$.next(triggerCount);
    });
  }

  subscribeToScheduleChanges(): Subscription {
    return this.form.valueChanges.subscribe(() => this.checkForValidationErrors());
  }

  onTabChange(tab: TaskDialogTabs) {
    this.selectedTab$.next(tab);
  }

  onPanelChange(selectedPanel: TaskDialogPanels) {
    this.selectedPanel$.next(selectedPanel);
  }

  onSave(duplicate?: boolean) {
    if (this.template) {
      this.onSaveTemplate();
      return;
    }
    this.checkForValidationErrors();
    // Start watching for event time changes to re-validate
    this.subscriptions.add(this.subscribeToScheduleChanges());
    if (!this.validate()) {
      return;
    }

    this.loadingSave$.next(true);
    this.updateProject(false, duplicate);
  }

  onSaveTemplate(manuallyCreateTemplate?: boolean) {
    if (!this.form.valid) {
      return;
    }
    this.loadingSave$.next(true);
    if (!!this.task && !manuallyCreateTemplate) {
      this.updateTaskTemplate();
    } else {
      this.createTaskTemplate();
    }
  }

  onCancel() {
    this.dialog.close();
    this.form.reset();
    this.onDeleteSubtaskImages();
  }

  onDelete() {
    this.selectedPanel$.next(
      this.template ? TaskDialogPanels.DeleteTemplateConfirmation : TaskDialogPanels.DeleteTaskConfirmation
    );
  }

  onConfirmRemoveTask() {
    this.updateProject(true);
    this.findAllTaskImagesToDelete();
  }

  onConfirmDeleteTemplate() {
    this.store$.dispatch(
      new DeleteTaskTemplateAction({
        id: `${this.task._id}`,
        onSuccess: () => this.onDeleteTemplateSuccess(),
      })
    );
  }

  onDeleteTemplateSuccess() {
    this.findAllTaskImagesToDelete();
    this.store$.dispatch(new GetTaskTemplatesAction({search: this.templateSearchValue}));
    this.dialog.close();
    this.form.reset();
  }

  onConfirmationCancel() {
    this.selectedPanel$.next(TaskDialogPanels.Default);
  }

  findAllTaskImagesToDelete() {
    let images: string[] = [];
    // Find all the images to delete along with the task
    (this.form.value.subtasks || []).forEach((subtask, index) => {
      images = [...images, ...this.tasksService.getImgArrayFromHTMLString(subtask.body)];
    });
    this.imagesToDelete = [...this.imagesToDelete, ...images];
    this.onDeleteSubtaskImages();
  }

  validate(): boolean {
    if (this.eventControl.value) {
      const valid =
        this.form.valid &&
        this.eventTimesControls.valid &&
        !!this.eventStartControl &&
        !!this.eventEndControl.value &&
        this.areTriggersComplete;
      if (!valid) {
        // if errors, go to panel and tab where error exists
        this.selectedPanel$.next(TaskDialogPanels.Default);
        this.selectedTab$.next(TaskDialogTabs.Schedule);
      }
      return valid;
    }
    if (
      this.scheduleTypeControl.value === 'repeats' &&
      this.repeatTypeControl.value === 'week' &&
      this.repeatDaysControl.value?.length === 0
    ) {
      // if no week days have been selected on task repeat
      this.selectedPanel$.next(TaskDialogPanels.Default);
      this.selectedTab$.next(TaskDialogTabs.Schedule);
      return false;
    }
    if (!this.areTriggersComplete) {
      this.selectedPanel$.next(TaskDialogPanels.Default);
      this.selectedTab$.next(TaskDialogTabs.Triggers);
      return false;
    }
    this.validationErrors$.next({});
    return this.form.valid;
  }

  createTaskTemplate() {
    const task = this.tasksService.formatTaskTemplateFromForm(this.form);
    this.store$.dispatch(
      new CreateTaskTemplateAction({
        task,
        onSuccess: (task: ProjectTask) => this.onSaveTaskTemplateSuccess(),
        onFailure: err => this.loadingSave$.next(false),
      })
    );
  }

  updateTaskTemplate() {
    const formattedTask = this.tasksService.formatTaskTemplateFromForm(this.form);
    const task = {...this.task, ...formattedTask};

    this.store$.dispatch(
      new UpdateTaskTemplateAction({
        task,
        onSuccess: (task: ProjectTask) => this.onSaveTaskTemplateSuccess(),
        onFailure: err => this.onSaveTaskTemplateFailure(),
      })
    );
    1;
  }

  onSaveTaskTemplateSuccess() {
    this.store$.dispatch(new GetTaskTemplatesAction({search: this.templateSearchValue}));
    this.dialog.close(true);
    this.form.reset();
    this.onDeleteSubtaskImages();
  }

  onSaveTaskTemplateFailure() {
    this.loadingSave$.next(false);
    this.messageService.add('There was a problem saving the task.');
  }

  updateProject(taskDeleted: boolean, duplicate?: boolean) {
    const formattedTask = this.tasksService.formatProjectTaskFromForm(this.form);
    if (duplicate) {
      this.task = null;
    }
    const task = {...this.task, ...formattedTask};
    const projectPartial = this.projectsService.getProjectFields(this.project);
    this.store$.dispatch(
      new UpdateProjectTasksAction({
        id: this.project._id,
        projectPartial,
        task: task || null,
        taskDeleted: taskDeleted || false,
        onSuccess: () => this.onUpdateProjectSuccess(this.project._id),
        onFailure: err => this.onUpdateProjectFailure(err),
      })
    );
  }

  onUpdateProjectSuccess(id: string) {
    this.dialog.close(true);
    this.form.reset();
    this.loadingSave$.next(false);
    this.onDeleteSubtaskImages();
  }

  onUpdateProjectFailure(err: Error) {
    this.messageService.add(err?.message);
    this.loadingSave$.next(false);
  }

  checkForValidationErrors() {
    this.validationErrors$.next({
      eventStart: !this.eventStartControl.value,
      eventEnd: !this.eventEndControl.value,
      repeatDays: this.scheduleTypeControl.value === 'repeats' && this.repeatDaysControl.value?.length === 0,
      triggersComplete: !this.areTriggersComplete,
    });
  }

  onImagesToDeleteChange(imagesToDelete: string[]) {
    this.imagesToDelete = imagesToDelete;
  }

  onDeleteSubtaskImages() {
    if (!this.imagesToDelete?.length) {
      return;
    }
    const idString = this.imagesToDelete.join(',');
    const ids = encodeURIComponent(idString);
    this.store$.dispatch(
      new DeleteImageAction({
        ids,
        onFailure: err => this.messageService.add(err.error),
      })
    );
  }

  get iconControl(): AbstractControl {
    return this.form.get('icon');
  }

  get eventControl(): AbstractControl {
    return this.form.get('event');
  }

  get scheduleControls(): AbstractControl {
    return this.form.get('schedule');
  }

  get eventTimesControls(): AbstractControl {
    return this.scheduleControls.get('eventTimes');
  }

  get scheduleTypeControl(): AbstractControl {
    return this.scheduleControls.get('scheduleType');
  }

  get repeatTypeControl(): AbstractControl {
    return this.scheduleControls.get('repeatType');
  }

  get repeatDaysControl(): AbstractControl {
    return this.scheduleControls.get('repeatDays');
  }

  get eventStartControl(): AbstractControl {
    return this.eventTimesControls.get('eventStart');
  }

  get eventEndControl(): AbstractControl {
    return this.eventTimesControls.get('eventEnd');
  }

  get triggerFormArray(): FormArray {
    return this.form.get('triggers') as FormArray;
  }

  get subtaskFormArray(): FormArray {
    return this.form.get('subtasks') as FormArray;
  }

  get autoResetControl(): AbstractControl {
    return this.form.get('autoReset');
  }
}
