@if (!(isMobile$ | async)) {
  <div class="desktop">
    <div class="left-container">
      <textarea
        placeholder="Task description"
        [formControl]="descriptionControl"
        [style.width]="template ? '100%' : '266px'"
      ></textarea>
    </div>
    @if (!template) {
      <div class="right-container">
        <div class="right-top-container">
          <div class="priority-container">
            <span class="label">Priority</span>
            <task-priority-selector [control]="priorityControl"></task-priority-selector>
          </div>
          <div class="requirements-container">
            <provisions-selector
              [form]="form"
              [teamsMap]="teamsMap$ | async"
              [usersMap]="usersMap$ | async"
              [requirementsMap]="requirementsMap$ | async"
              (panelChange)="panelChange.emit($event)"
            ></provisions-selector>
          </div>
        </div>
        <task-location-selector [control]="locationControl" lightBackground="true"></task-location-selector>
      </div>
    }
  </div>
} @else {
  <div class="mobile">
    <div class="priority-container">
      <span class="label">Priority</span>
      <task-priority-selector [control]="priorityControl"></task-priority-selector>
    </div>
    <div class="mobile-requirements-container">
      <provisions-selector
        [form]="form"
        [teamsMap]="teamsMap$ | async"
        [usersMap]="usersMap$ | async"
        [requirementsMap]="requirementsMap$ | async"
        (panelChange)="panelChange.emit($event)"
      ></provisions-selector>
    </div>
    <task-location-selector [control]="locationControl" lightBackground="true"></task-location-selector>
    <div class="textarea-container">
      <textarea placeholder="Task description" [formControl]="descriptionControl"></textarea>
    </div>
  </div>
}
