import {AsyncPipe, CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'oph-input-orange',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, AsyncPipe, MatProgressSpinnerModule, MatIconModule],
  templateUrl: './oph-input-orange.component.html',
  styleUrl: './oph-input-orange.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OphInputOrangeComponent {
  @Input() orangeBorder: boolean;
  @Input() control: FormControl;
  @Input() inputStyle: Object;
  @Input() type: string = 'text';
  @Input() showUpdateButton: boolean;
  @Input() buttonStyle: Object;
  @Input() updateButtonStatus: string; // loading, success, null

  @Output() update = new EventEmitter<string>();

  @ViewChild('input') input: ElementRef;

  inputFocused$ = new BehaviorSubject<boolean>(false);

  onFocus() {
    this.inputFocused$.next(true);
  }

  onBlur() {
    this.inputFocused$.next(false);
  }

  onUpdate(event: PointerEvent) {
    if (this.updateButtonStatus) {
      return;
    }
    this.update.emit(this.input.nativeElement.value || null);
  }
}
